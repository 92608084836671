import React from "react"
import { Link } from "gatsby"
const Breadcrumbs = ({ breadcrumbs }) => (
  <section>
    <div className="relative block-w-container">
      <div className="inline-block -ml-2 text-sm">
        {breadcrumbs?.map((breadcrumb, index) => (
          <span key={index}>
            {index > 0 && (
              <span className="inline-block h-4 text-ro-gray-600">/</span>
            )}
            <Link
              className={`px-2 hover:underline text-ro-gray-400`}
              key={index}
              to={breadcrumb.link}
            >
              {breadcrumb.link_text}
            </Link>
          </span>
        ))}
      </div>
    </div>
  </section>
)

export default Breadcrumbs
