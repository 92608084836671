import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"

import * as moment from "moment"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Pagination from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Pagination"

import SEO from "gatsby-theme-rothenberger/src/@yumgmbh/gatsby-theme-yum-components/components/modules/Seo"
import BreadcrumbsMobileDropdown from "../../../../components/modules/BreadcrumbsMobileDropdown"
import HeroBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/HeroBlock"

const NewsOverviewPage = ({
  full_slug,
  content,
  news,
  language,
  numPages,
  currentPage,
  breadcrumbs,
  name,
}) => {
  let seoImage = null

  if (
    !content.image?.filename &&
    content.body &&
    content.body[0] &&
    content.body[0].image &&
    content.body[0].image.filename
  ) {
    seoImage = content.body[0].image.filename
  } else {
    seoImage = content.image?.filename
  }

  let preparedNews =
    news?.map((n) => {
      return Object.assign({}, n, { content: JSON.parse(n.content) })
    }) || []

  preparedNews = preparedNews.map((n) => {
    if (
      !n.content.image.filename &&
      n.content.body &&
      n.content.body[0] &&
      n.content.body[0].image &&
      n.content.body[0].image.filename
    ) {
      n.content.image = n.content.body[0].image
    }
    if (!n.content.image.filename) {
      n.content.image = { filename: process.env.GATSBY_IMG_PLACEHOLDER }
    }

    return n
  })

  let pagePath = full_slug || null

  if (pagePath && pagePath[pagePath.length - 1] === "/") {
    pagePath = pagePath.slice(0, pagePath.length - 1)
  }

  const nextPage = `${pagePath}/${String(currentPage + 1)}`
  const prevPage =
    currentPage - 1 === 1
      ? `${pagePath}`
      : `${pagePath}/${String(currentPage - 1)}`

  const bodySplitIndex =
    content.body.findIndex(
      (block) => block.component === "news_listing_placeholder_block"
    ) || null

  let topBody = []
  let bottomBody = content.body

  if (bodySplitIndex) {
    topBody = content.body.slice(0, bodySplitIndex)
    bottomBody = content.body.slice(bodySplitIndex + 1)
  }

  return (
    <Layout language={language}>
      <SEO
        title={content.headline}
        content={content}
        og_image={seoImage}
        language={language}
      />
      {topBody && topBody.length > 0 && (
        <>
          {topBody[0].component !== "hero_block" &&
            topBody[0].component !== "hero_overlay_block" && (
              <HeroBlock
                block={{ headline: content.headline || name }}
                breadcrumbs={breadcrumbs}
              />
            )}
          <BodyBlocks body={topBody} breadcrumbs={breadcrumbs} />
        </>
      )}

      <section className="py-10">
        <div className="block-w-container">
          <div className="flex flex-row flex-wrap -mx-grid">
            {preparedNews.length > 0 &&
              preparedNews.map((item) => (
                <div
                  className="w-full px-grid md:w-1/2 lg:w-1/4"
                  key={item.full_slug}
                >
                  <Link
                    className="flex flex-col justify-between h-full pb-10"
                    link={item.fields.full_slug_prepared}
                  >
                    <div className="block w-full border border-ro-gray-100 aspect-square">
                      <Image
                        className="object-contain w-full h-auto"
                        image={item.content.image}
                        fitIn={true}
                        fixedSize="350x350"
                        imageWidth="350"
                      />
                    </div>
                    <div className="flex-1 p-4 -mt-px border border-ro-gray-100">
                      <div>
                        <div className="mb-2">
                          <span className="inline-block w-3 h-3 align-baseline bg-ro-red"></span>
                          {item.content.display_date?.length > 0 && (
                            <span className="ml-4 prose-sm">
                              {moment(item.content.display_date).format(
                                "DD.MM.YYYY"
                              )}
                            </span>
                          )}
                        </div>
                        <span className="font-bold text-ro-red">
                          {item.content.title}
                        </span>
                        <Richtext
                          className="mt-2 prose-sm"
                          text={item.content.teaser}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </div>
      </section>
      {preparedNews.length > 0 && (
        <Pagination
          currentPage={currentPage}
          isFirstPage={currentPage === 1}
          isLastPage={currentPage === numPages}
          totalPages={numPages}
          pagePath={pagePath}
          nextPage={nextPage}
          prevPage={prevPage}
        />
      )}
      {bottomBody && bottomBody.length > 0 && <BodyBlocks body={bottomBody} />}
    </Layout>
  )
}

export default NewsOverviewPage
